<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'FlatDocument', title: 'Документи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_documents)"
        :modal="show_documents_dialog"
        @updateItemModal="documentsUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Перелік документів
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <YouTubeButton
            button-class="grey lighten-4 mr-1"
            icon-color="error lighten-1"
            :icon-size="26"
            href="https://youtu.be/NU_VoPQwAQk"
        />
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openDocumentsCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий документ</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="tableHeaders"
        :items="documents"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onDocumentsItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <div class="d-flex">
          <v-icon size="26" class="mr-2" :color="item.files.length ? 'success' : 'secondary'">
            {{ item.files.length ? 'mdi-paperclip' : 'mdi-file-hidden' }}
          </v-icon>
          <div class="icon-text">
            {{ `${item.files.length}` }}
          </div>
        </div>

<!--        <MainModal-->
<!--            :main="{ component: 'FlatDocument', title: 'Документи' }"-->
<!--            :button="{-->
<!--                      iconActivator: true,-->
<!--                      class: 'mr-2',-->
<!--                      color: item.files.length ? 'success' : 'secondary',-->
<!--                      buttonIcon: item.files.length ? 'mdi-paperclip' : 'mdi-file-hidden',-->
<!--                      iconSize: 26,-->
<!--                      iconText: `${item.files.length}`-->
<!--                    }"-->
<!--            :item="{-->
<!--                      id: item.id,-->
<!--                      flat_id: flat.id,-->
<!--                      document_type: item.document_type,-->
<!--                      document_date: item.document_date,-->
<!--                      document_number: item.document_number,-->
<!--                      comment: item.comment,-->
<!--                      files: copyObject(item.files)-->
<!--                    }"-->
<!--            :modal="dialog_id === item.id"-->
<!--            @updateItemModal="updateItemModal"-->
<!--        />-->
      </template>
      <template v-slot:item.document_type="{ item }">
        <span>
          {{ getOwnerTypeTranslate(item.document_type) }}
        </span>
      </template>
      <template v-slot:item.document_date="{ item }">
        <span>
          {{ item.document_date | formatDate }}
        </span>
      </template>
      <template v-slot:item.create_date="{ item }">
        <span>
          {{ item.create_date | formatDate }}
        </span>
      </template>
      <template v-slot:item.value_dec="{ item }">
        <span>
          {{ item.value_dec | formatNumber }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapGetters, mapActions} from "vuex";
import {GET_FLAT_DOCUMENTS} from "@/store/actions/flat_documents";
import {document_ownership_types_select} from "@/utils/icons";

export default {
  name: "HWP_FlatDocuments",
  props: {},
  components: {
    MainModal,
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  data() {
    return {
      selected_documents: {},
      show_documents_dialog: false,
      tableHeaders: [
        { text: '', value: 'icon', width: 20 },
        { text: 'Вид документа', value: 'document_type' },
        { text: 'Дата документу', value: 'document_date' },
        { text: '№ документу', value: 'document_number' },
        { text: 'Коментар', value: 'comment' },
        { text: 'Створено', value: 'create_date' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      documents: 'getFlatDocuments',
      flat: 'getFlat'
    })
  },
  methods: {
    ...mapActions({
      fetch_documents: GET_FLAT_DOCUMENTS
    }),
    copyObject(obj) {
      return obj.map(i => {
        return {...i, delete: false}
      })
    },
    getOwnerTypeTranslate(name) {
      const obj = document_ownership_types_select.find(item => item.value === name)
      return obj ? obj.text : name
    },
    documentsUpdateItemModal() {
      this.show_documents_dialog = false
      this.selected_documents = {}
    },
    openDocumentsCreateDialog() {
      this.selected_documents = {
        flat_id: this.flat.id
      }
      this.show_documents_dialog = true
    },
    onDocumentsItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.flat_id = this.flat.id
      local_payload.files = this.copyObject(local_payload.files)

      this.selected_documents = local_payload
      this.show_documents_dialog = true
    },
  },
  created() {
    this.fetch_documents(this.flat.id)
  }
}
</script>

<style scoped lang="scss">
.icon-text {
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background: #4eb05226;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: .85rem;
  margin-top: 2px;
}
</style>